import React from 'react';
import wishlistButtonStylable from './WishlistButton.st.css';
import wishlistIconButtonStyle from './WishlistIconButton.scss';
import {Button} from 'wix-ui-tpa/Button';
import {Heart} from 'wix-ui-tpa/dist/src/assets/icons';
import classNames from 'classnames';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {StatesButton} from 'wix-ui-tpa/StatesButton';

export interface WishlistButtonProps extends ProvidedGlobalProps, IProvidedTranslationProps {}

@withGlobalProps
@withTranslations('globals.texts')
export class WishlistButton extends React.Component<WishlistButtonProps> {
  public AddToWishlistButtonRef = React.createRef<StatesButton>();

  public componentDidUpdate(prevProps: Readonly<WishlistButtonProps>): void {
    const {addedToWishlistSuccessfully} = this.props.globals;

    if (
      addedToWishlistSuccessfully !== prevProps.globals.addedToWishlistSuccessfully &&
      !this.shouldRenderIconButton()
    ) {
      //tslint:disable-next-line no-floating-promises
      this.AddToWishlistButtonRef.current.onProgressReset();
    }
  }

  private readonly handleWishlistButtonClick = () => {
    const {handleWishlistButtonClick} = this.props.globals;

    handleWishlistButtonClick();
  };

  private getButtonAriaLabel() {
    const {
      t,
      globals: {productWasAddedToWishlist},
    } = this.props;

    return productWasAddedToWishlist ? t('sr.wishlist.remove.button') : t('sr.wishlist.button');
  }

  private renderIconButton() {
    const {productWasAddedToWishlist, isMobile} = this.props.globals;

    const buttonClasses = classNames(
      wishlistButtonStylable.wishlistButton,
      wishlistIconButtonStyle.wishlistIconButton,
      {
        [wishlistButtonStylable.mobileDisplay]: isMobile,
        [wishlistIconButtonStyle.mobileDisplay]: isMobile,
      }
    );
    const heartClass = productWasAddedToWishlist ? wishlistIconButtonStyle.filled : wishlistIconButtonStyle.empty;

    return (
      <Button
        onClick={this.handleWishlistButtonClick}
        className={buttonClasses}
        data-hook={'wishlist-button'}
        aria-label={this.getButtonAriaLabel()}
        aria-pressed={productWasAddedToWishlist}>
        <div className={wishlistIconButtonStyle.iconContainer}>
          <Heart
            className={classNames(wishlistIconButtonStyle.heartIcon, heartClass)}
            data-hook={'wishlist-button-icon'}
          />
        </div>
      </Button>
    );
  }

  private renderFullWidthButton() {
    const {
      t,
      globals: {productWasAddedToWishlist},
    } = this.props;

    const caption = productWasAddedToWishlist ? t('wishlist.remove.button') : t('wishlist.button');

    return (
      <StatesButton
        ref={this.AddToWishlistButtonRef}
        text={caption}
        onClick={this.handleWishlistButtonClick}
        className={wishlistButtonStylable.wishlistFullWidthButton}
        disabled={false}
        fullWidth
        dataHook={'wishlist-states-button'}
      />
    );
  }

  private shouldRenderIconButton() {
    const {
      globals: {shouldShowAddToCartButton, shouldShowBuyNowButton, isMobile},
    } = this.props;

    return shouldShowAddToCartButton || shouldShowBuyNowButton || isMobile;
  }

  public render() {
    return this.shouldRenderIconButton() ? this.renderIconButton() : this.renderFullWidthButton();
  }
}
