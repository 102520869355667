import React from 'react';
import {IPropsInjectedByViewerScript} from '../types/app-types';
import {IHostProps, ISantaProps} from '@wix/native-components-infra/dist/es/src/types/types';

const PropsContext = React.createContext<ProvidedGlobalProps>(undefined);

export interface ProvidedGlobalProps {
  globals?: GlobalProps;
}

export type GlobalProps = Pick<
  IPropsInjectedByViewerScript,
  // tslint:disable-next-line:max-union-size
  | 'isProductSubmitted'
  | 'experiments'
  | 'handleAddToCart'
  | 'handleWishlistButtonClick'
  | 'handleUserInput'
  | 'hasMultipleMedia'
  | 'hideNavigationUrls'
  | 'infoSection'
  | 'isInteractive'
  | 'isDesktop'
  | 'isRTL'
  | 'isSSR'
  | 'isSEO'
  | 'navigate'
  | 'pagePath'
  | 'pagination'
  | 'product'
  | 'productUrl'
  | 'quantityRange'
  | 'selectedVariant'
  | 'shouldFocusAddToCartButton'
  | 'siteUrl'
  | 'socialSharing'
  | 'texts'
  | 'userInputErrors'
  | 'userInputs'
  | 'validate'
  | 'isMobile'
  | 'addedToCartSuccessfully'
  | 'addedToWishlistSuccessfully'
  | 'shouldFocusNewAddToCartButton'
  | 'onAppInteractive'
  | 'onAppLoaded'
  | 'shouldShowAddToCartSuccessAnimation'
  | 'subscriptionPlans'
  | 'shouldShowAddToCartButton'
  | 'shouldShowWishlistButton'
  | 'productWasAddedToWishlist'
  | 'shouldShowBuyNowButton'
  | 'handleBuyNow'
> &
  // tslint:disable-next-line:max-union-size
  Pick<IHostProps, 'updateLayout' | 'dimensions' | 'appLoadBI' | 'style'>;

export function withGlobalPropsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & ISantaProps & {style: IHostProps['style']}) => {
    const {
      isProductSubmitted,
      experiments,
      handleAddToCart,
      handleWishlistButtonClick,
      handleUserInput,
      hasMultipleMedia,
      hideNavigationUrls,
      infoSection,
      isInteractive,
      isDesktop,
      isRTL,
      isSSR,
      isSEO,
      navigate,
      pagePath,
      pagination,
      product,
      productUrl,
      quantityRange,
      selectedVariant,
      shouldFocusAddToCartButton,
      shouldFocusNewAddToCartButton,
      siteUrl,
      socialSharing,
      style,
      texts,
      userInputErrors,
      userInputs,
      validate,
      isMobile,
      addedToCartSuccessfully,
      addedToWishlistSuccessfully,
      onAppInteractive,
      onAppLoaded,
      shouldShowAddToCartSuccessAnimation,
      subscriptionPlans,
      shouldShowAddToCartButton,
      shouldShowWishlistButton,
      productWasAddedToWishlist,
      shouldShowBuyNowButton,
      handleBuyNow,
      ...passThroughProps
    } = props;

    const {appLoadBI, dimensions, updateLayout} = props.host;

    const globals = {
      isProductSubmitted,
      appLoadBI,
      dimensions,
      experiments,
      handleAddToCart,
      handleWishlistButtonClick,
      handleUserInput,
      hasMultipleMedia,
      hideNavigationUrls,
      infoSection,
      isInteractive,
      isDesktop,
      isRTL,
      isSSR,
      isSEO,
      navigate,
      pagePath,
      pagination,
      product,
      productUrl,
      quantityRange,
      selectedVariant,
      shouldFocusAddToCartButton,
      shouldFocusNewAddToCartButton,
      siteUrl,
      socialSharing,
      style,
      texts,
      updateLayout,
      userInputErrors,
      userInputs,
      validate,
      isMobile,
      addedToCartSuccessfully,
      addedToWishlistSuccessfully,
      onAppInteractive,
      onAppLoaded,
      shouldShowAddToCartSuccessAnimation,
      subscriptionPlans,
      shouldShowAddToCartButton,
      shouldShowWishlistButton,
      productWasAddedToWishlist,
      shouldShowBuyNowButton,
      handleBuyNow,
    };

    return (
      <PropsContext.Provider value={{globals}}>
        <Component {...passThroughProps} />
      </PropsContext.Provider>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return props => {
    return <PropsContext.Consumer>{globalProps => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };
}

export function withDirection(WrappedComponent: React.ComponentType): React.FunctionComponent | any {
  return props => {
    return (
      <PropsContext.Consumer>
        {globalProps => {
          const isRTL = globalProps.globals.isRTL;
          const dir = isRTL ? 'rtl' : 'ltr';
          return (
            <div dir={dir}>
              <WrappedComponent {...props} />
            </div>
          );
        }}
      </PropsContext.Consumer>
    );
  };
}
